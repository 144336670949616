import type { RouteLocationNormalizedGeneric } from 'vue-router';
import { SponsorType, type RegisterStep } from '~/composables/useRegisterSteps';

export default defineNuxtRouteMiddleware(
   async (
      to: RouteLocationNormalizedGeneric,
      from: RouteLocationNormalizedGeneric
   ) => {
      const { getTherapyTypes, getLanguages } = useSharedContentStore();
      const { therapyTypes, languages } = storeToRefs(useSharedContentStore());
      const { anonymousId } = useAnonymousClientStorage();
      const {
         getAnonymousData,
         createAnonymousId,
         updateAnonymousData,
         getNextStep,
         getLoggedTherapyTypes,
         verifyInsuranceBenefitCode,
         verifyCompanyBenefitCode,
         getCoverageDetail,
      } = useClientAuthStore();
      const { anonymousData, coverageDetailBody } =
         storeToRefs(useClientAuthStore());
      const { isClientAuthenticated } = useAuthClientStorage();
      const { getClientInfo, getSelectedTherapistInfo } = useClientInfo();
      const { clientProfile, status } = storeToRefs(useClientInfo());
      const { currentRegisterStep } = useRegisterSteps();
      if (anonymousId.value || isClientAuthenticated.value) {
         if (isClientAuthenticated.value && !clientProfile.value) {
            await Promise.all([getClientInfo(), getSelectedTherapistInfo()]);
         } else if (!isClientAuthenticated.value && !anonymousData.value) {
            await getAnonymousData();
         }
         if (!languages.value) {
            getLanguages();
         }
         const isInsurance =
            clientProfile.value?.sponsor == SponsorType.Insurance ||
            anonymousData.value?.sponsor == SponsorType.Insurance;
         if (
            isInsurance &&
            status.value?.statusCode == 0 &&
            status.value?.route?.to?.name != to.name
         ) {
            const currentStepIndex = currentRegisterStep?.value?.findIndex(
               (step: RegisterStep) => step.to.name === to.name
            );
            const targetStepIndex = currentRegisterStep?.value?.findIndex(
               (step: RegisterStep) =>
                  step.to.name === status.value?.route?.to?.name
            );

            if (currentStepIndex >= targetStepIndex) {
               if (
                  status.value?.route?.to?.name !=
                  'auth-client-register-confirmedInsuranceBenefitCode'
               ) {
                  return navigateTo(status.value?.route?.to);
               }
            }
         }
      } else {
         if (to.name == 'auth-client-register-coverage') {
            return await createAnonymousId();
         } else {
            return navigateTo({ name: 'auth-client-login' });
         }
      }
      const sponsorType =
         clientProfile.value?.sponsor || anonymousData.value?.sponsor;
      const currentStepIndex = currentRegisterStep?.value?.findIndex(
         (step: RegisterStep) => step.to.name === from.name
      );
      const targetStepIndex = currentRegisterStep?.value?.findIndex(
         (step: RegisterStep) => step.to.name === to.name
      );
      if (to.name == 'auth-client-register-confirmedCompanyBenefit') {
         if (isClientAuthenticated.value && !coverageDetailBody.value) {
            await getCoverageDetail();
         } else if (!isClientAuthenticated.value) {
            await verifyCompanyBenefitCode({
               benefit_code: anonymousData.value?.benefit_code || '',
               email: anonymousData.value?.email || '',
            });
         }
      }
      if (to.name == 'auth-client-register-confirmedInsuranceBenefitCode') {
         if (isClientAuthenticated.value && !coverageDetailBody.value) {
            await getCoverageDetail();
         } else if (!isClientAuthenticated.value) {
            await verifyInsuranceBenefitCode({
               benefit_code: anonymousData.value?.benefit_code || '',
               country_id: anonymousData.value?.country_id || '',
               date_of_birth: anonymousData.value?.date_of_birth || '',
               email: anonymousData.value?.email || '',
               first_name: anonymousData.value?.first_name || '',
               last_name: anonymousData.value?.last_name || '',
               insurance_id: anonymousData.value?.insurance_id || '',
               national_id: anonymousData.value?.national_id || '',
            });
         }
      }
      if (to.name == 'auth-client-register-therapy-type') {
         if (sponsorType == SponsorType.Company) {
            if (isClientAuthenticated.value) {
               await Promise.all([
                  getLoggedTherapyTypes(),
                  getCoverageDetail(),
               ]);
            } else {
               await verifyCompanyBenefitCode({
                  benefit_code:
                     coverageDetailBody.value?.coverage?.benefit_code ||
                     anonymousData.value?.benefit_code ||
                     '',
                  email:
                     clientProfile.value?.email ||
                     anonymousData.value?.email ||
                     '',
               });
            }
         } else if (sponsorType == SponsorType.Insurance) {
            if (isClientAuthenticated.value) {
               await Promise.all([
                  getLoggedTherapyTypes(),
                  getCoverageDetail(),
               ]);
            } else {
               await Promise.all([getTherapyTypes(), getCoverageDetail()]);
            }
         } else {
            await getTherapyTypes();
         }
         if (therapyTypes.value?.length == 1) {
            await updateAnonymousData({
               therapy_type_id: therapyTypes.value[0]?.id,
            });
            if (currentStepIndex < targetStepIndex && currentStepIndex != -1) {
               const toRoute = getNextStep(true)?.to;
               if (toRoute) return navigateTo(toRoute);
            }
         }
      }
      return;
   }
);
