import { useCustomerIo } from './useCustomerIo';

// Define types for better type safety
interface Analytics {
   identify(userId: string, traits?: Record<string, any>): void;
   track(event: string, properties?: Record<string, any>): void;
   page(properties?: Record<string, any>): void;
   reset(): void;
   group(groupId: string, traits?: Record<string, any>): void;
   alias(userId: string, previousId?: string): void;
}

declare global {
   interface Window {
      analytics?: Analytics;
   }
}

export const useSegment = () => {
   const { identifyUser, resetUser, trackEvent, trackPageView } =
      useCustomerIo();

   const isAnalyticsAvailable = (): boolean => {
      return typeof window !== 'undefined' && !!window.analytics;
   };

   const identify = (userId: string, traits?: Record<string, any>) => {
      try {
         if (isAnalyticsAvailable()) {
            window.analytics?.identify(userId, traits);
         }
         identifyUser(traits);
      } catch (error) {
         console.error('Error in Segment identify:', error);
      }
   };

   const track = (event: string, properties?: Record<string, any>) => {
      try {
         if (isAnalyticsAvailable()) {
            window.analytics?.track(event, properties);
         }
         trackEvent(event, properties);
      } catch (error) {
         console.error('Error in Segment track:', error);
      }
   };

   const page = (properties?: Record<string, any>) => {
      try {
         if (isAnalyticsAvailable()) {
            window.analytics?.page({ page: properties });
         }
         trackPageView(properties);
      } catch (error) {
         console.error('Error in Segment page:', error);
      }
   };

   const reset = () => {
      try {
         if (isAnalyticsAvailable()) {
            window.analytics?.reset();
         }
         resetUser();
      } catch (error) {
         console.error('Error in Segment reset:', error);
      }
   };

   const group = (groupId: string, traits?: Record<string, any>) => {
      try {
         if (isAnalyticsAvailable()) {
            window.analytics?.group(groupId, traits);
         }
      } catch (error) {
         console.error('Error in Segment group:', error);
      }
   };

   const alias = (userId: string, previousId?: string) => {
      try {
         if (isAnalyticsAvailable()) {
            window.analytics?.alias(userId, previousId);
         }
      } catch (error) {
         console.error('Error in Segment alias:', error);
      }
   };

   return {
      identify,
      track,
      page,
      reset,
      group,
      alias,
   };
};
