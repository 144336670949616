import { useIFetch } from '@/composables/useIFetch';
import apiRoutes from '@/const/apiRoutes';
import type { ClientInfo, ClientProfile } from '@/types/clientInformation';
import type { Education, License } from '@/types/therapistInformation';
import { type RegisterStep } from '~/composables/useRegisterSteps';
import type { TherapistInformation } from '~/types/therapistInformation';

export const useClientInfo = defineStore('clientInfo', () => {
   const { identify } = useSegment();
   const { setUser } = useSentry();
   const route = computed(() => useRoute());
   const { setSnackbarDetail } = useSnackbarStore();
   const clientInformation: Ref<ClientInfo | null> = ref(null);
   const currentTherapistDetail: Ref<TherapistInformation | null> = ref(null);
   const therapistEducationList: Ref<Education[]> = ref([]);
   const therapistLicenseList: Ref<License[]> = ref([]);
   const clientRole: Ref<any | null> = ref(null); //to add type
   const { switchStepBasedByData, currentRegisterStep } = useRegisterSteps();
   const clientProfile: Ref<ClientProfile | null> = ref(null);
   const needToChangeTimeZone = ref(false);
   const isClient = computed(() => {
      return route?.value?.meta?.layout == 'client';
   });
   const clientHasSubscription = computed(() => {
      return clientInformation.value?.client?.has_subscription;
   });
   const fromProfileChangeTherapist = ref(false);
   const clientImage: Ref<string | null> = ref(
      clientProfile?.value?.image || null
   );
   const setRedirectionUrl = (detail: boolean) => {
      fromProfileChangeTherapist.value = detail;
   };
   const status: Ref<{
      statusCode?: number;
      route?: RegisterStep;
   } | null> = ref(null);
   const getClientRole = async () => {
      try {
         const { data, error } = await useIFetch(apiRoutes?.auth?.userRole);
         clientRole.value = data.value;
         if (error.value) {
            setSnackbarDetail({
               show: true,
               title: 'Something went wrong',
               type: 'error',
            });
         }
      } catch (e: any) {
         setSnackbarDetail({
            show: true,
            title: e?.message,
            type: 'error',
         }); //change later to use snackbar store(need to create)
      }
   };

   const updateStatus = () => {
      const to = switchStepBasedByData();
      status.value = {
         statusCode: to ? 0 : 1,
         route: to ?? undefined,
      };
   };
   const updateClientImage = (file_id: string) => {
      clientImage.value = file_id;
      if (clientProfile.value) clientProfile.value.image = file_id;
   };

   const getClientInfo = async () => {
      const { data, error } = await useIFetch<ClientProfile | null>(
         apiRoutes?.client?.profile?.get
      );
      clientProfile.value = data?.value;
      clientImage.value = data?.value?.image || null;
      updateStatus();
      if (data.value) {
         const detectedTimeZone =
            Intl.DateTimeFormat().resolvedOptions().timeZone;
         needToChangeTimeZone.value = data?.value?.timezone != detectedTimeZone;
         setUser({ id: data.value.id, email: data.value.email });
         identify({ id: data.value.id, email: data.value.email });
      }
      if (error?.value) {
         setSnackbarDetail({
            show: true,
            title: 'Something went wrong',
            type: 'error',
         });
      }
   };
   const updateClientInfo = async (formData: any) => {
      const { error, data } = await useIFetch(apiRoutes.client.profile.update, {
         body: formData,
         method: 'PUT',
      });
      if (error?.value) {
         setSnackbarDetail({
            show: true,
            title: error?.value?.data?.message,
            type: 'error',
         });
      } else {
         await getClientInfo();
      }
      return { data, error };
   };

   const deductClientCredit = () => {
      let credit = clientInformation?.value?.client?.credits;
      if (clientInformation.value)
         clientInformation.value.client.credits = credit ? credit - 1 : 0;
   };
   const getSelectedTherapistInfo = async () => {
      const { data } = await useIFetch<ClientInfo>(
         apiRoutes?.client?.sessions?.info
      );
      if (data?.value) {
         clientInformation.value = data?.value;
      } else {
         clientInformation.value = null;
      }
   };
   const loading = ref(true);
   const getCurrentTherapistDetail = async () => {
      const { data } = await useIFetch<TherapistInformation>(
         apiRoutes?.client?.selectedTherapist
      );
      if (data?.value) {
         currentTherapistDetail.value = data?.value;
         loading.value = false;
      } else {
         currentTherapistDetail.value = null;
      }
   };
   const getTherapistEducationLicenses = async () => {
      const { data } = await useIFetch<{
         educations: Education[];
         licenses: License[];
      }>(apiRoutes?.client?.therapistEducationLicense);
      if (data?.value) {
         therapistLicenseList.value = data?.value?.licenses;
         therapistEducationList.value = data?.value?.educations;
         loading.value = false;
      } else {
         therapistLicenseList.value = [];
         therapistEducationList.value = [];
      }
   };
   return {
      getClientInfo,
      clientProfile,
      setRedirectionUrl,
      fromProfileChangeTherapist,
      clientRole,
      getClientRole,
      deductClientCredit,
      currentTherapistDetail,
      loading,
      updateClientImage,
      clientImage,
      getCurrentTherapistDetail,
      getTherapistEducationLicenses,
      therapistEducationList,
      therapistLicenseList,
      getSelectedTherapistInfo,
      clientInformation,
      updateClientInfo,
      clientHasSubscription,
      isClient,
      status,
      updateStatus,
      needToChangeTimeZone,
   };
});
