<script setup lang="ts">
   import {
      Combobox,
      ComboboxButton,
      ComboboxInput,
      ComboboxOption,
      ComboboxOptions,
   } from '@headlessui/vue';
   import { CheckCircleIcon } from '@heroicons/vue/20/solid';
   import { ChevronUpDownIcon } from '@heroicons/vue/24/outline';
   import type { item } from '~/stores/sharedContent';
   const emit = defineEmits<{
      (event: 'handleQuery', value: string): void;
   }>();
   const props = defineProps<{
      options: Array<item>;
      multiple?: boolean;
      height?: string;
      removeMarginTop?: boolean;
      noOptionMessage?: string;
      placeholder?: string;
   }>();
   const selected = defineModel<string | string[] | undefined>('selected', {
      required: false,
   });

   const searchQuery = ref('');

   const selectedItem = computed(() => {
      if (selected.value) {
         if (props.multiple) {
            return (
               props.options?.filter((option) =>
                  (selected.value as string[]).includes(option?.id)
               ) || []
            );
         }
      }
   });
   const timeOut: Ref<NodeJS.Timeout | null> = ref(null);

   const searchHandler = (event) => {
      searchQuery.value = event?.target?.value;
      if (timeOut.value !== null) {
         clearTimeout(timeOut.value);
      }
      timeOut.value = setTimeout(() => {
         if (!props.multiple) {
            const searchId = findIdBySearch(searchQuery.value);
            if (searchId != selected.value) {
               selected.value = '';
            }
            if (!searchQuery.value) {
               selected.value = '';
            }
         }
         emit('handleQuery', searchQuery.value);
      }, 500);
   };

   const optionList = computed(() => {
      if (searchQuery.value && (props.multiple ? true : !selected.value)) {
         return props.options.filter((option) =>
            option?.name
               .toLowerCase()
               .startsWith(searchQuery?.value?.toLowerCase())
         );
      } else {
         return props.options;
      }
   });
   const setSearch = (id: string) => {
      if (id) {
         searchQuery.value =
            optionList?.value?.find((option) => id === option?.id)?.name || '';
      }
   };
   const findIdBySearch = (search: string) => {
      return optionList?.value?.find(
         (option) => option?.name.toLowerCase() == search.toLowerCase()
      )?.id;
   };
   watch(
      () => selected.value,
      () => {
         setSearch(selected.value as string);
      }
   );
   onMounted(() => {
      setSearch(selected.value as string);
   });
   const { t } = useI18n();
</script>
<template>
   <Combobox
      as="div"
      :class="removeMarginTop ? '' : 'mt-2'"
      class="relative"
      v-model="selected"
      @update:modelValue="searchQuery = ''"
      :multiple="multiple || undefined"
   >
      <div
         v-if="multiple"
         class="bg-white cursor-default overflow-hidden rounded-xl px-3 border border-gray-400 flex items-center gap-2 flex-wrap py-2"
      >
         <span
            class="block bg-primary-500/20 text-xs py-2 px-3 rounded"
            v-for="item in selectedItem as item[]"
            :id="item?.name"
            :key="item?.id"
         >
            {{ item?.name }}
         </span>
         <ComboboxInput
            class="relative cursor-default overflow-hidden rounded-xl py-2 focus:outline-none"
            @change="searchHandler"
            :value="searchQuery"
            :placeholder="placeholder || t('shared.search')"
         />
      </div>
      <ComboboxInput
         v-else
         :class="height ? height : 'min-h-14'"
         @change="searchHandler"
         :value="searchQuery"
         :placeholder="placeholder || 'Search'"
         class="relative w-full bg-white cursor-default overflow-hidden rounded-xl px-3 border border-gray-400 flex items-center gap-2 flex-wrap py-2 text-xs sm:text-base"
      >
      </ComboboxInput>

      <ComboboxButton
         class="absolute top-2.5 end-2 flex items-center w-full justify-end"
      >
         <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </ComboboxButton>
      <transition
         leave-active-class="transition ease-in duration-100"
         leave-from-class="opacity-100"
         leave-to-class="opacity-0"
      >
         <ComboboxOptions
            class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
         >
            <ComboboxOption
               as="template"
               v-for="option in optionList"
               :key="option?.id"
               :value="option?.id"
               :id="option.name"
               v-slot="{ active, selected }"
            >
               <li
                  :class="[
                     active ? 'bg-primary-200/20  ' : '',
                     'flex gap-3 items-center py-3 px-4',
                  ]"
                  class="cursor-pointer"
               >
                  <div>
                     <CheckCircleIcon
                        v-if="selected"
                        class="h-5 w-5 sm:h-6 sm:w-6 text-primary-500"
                     />
                     <p
                        v-else
                        class="h-5 w-5 border border-gray-100 rounded-full"
                     ></p>
                  </div>
                  <span class="font-semibold text-sm">{{ option?.name }}</span>
               </li>
            </ComboboxOption>

            <p
               v-if="!optionList?.length"
               class="text-sm text-gray-200 py-3 px-4"
            >
               {{ noOptionMessage || 'No options available' }}
            </p>
         </ComboboxOptions>
      </transition>
   </Combobox>
</template>
