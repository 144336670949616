<script setup lang="ts">
   import { ExclamationCircleIcon } from '@heroicons/vue/20/solid';
   const { isClient } = storeToRefs(useClientInfo());
   const { logoutAsClient } = useClientAuthStore();
   const { logoutAsTherapist } = useTherapistAuthStore();
   const { t } = useI18n();

   const emit = defineEmits<{
      (event: 'close'): void;
   }>();

   const logOut = () => {
      if (isClient?.value) {
         logoutAsClient();
      } else {
         logoutAsTherapist();
      }
   };

   const cancel = () => {
      emit('close');
   };
</script>

<template>
   <div class="flex flex-col justify-center items-center px-2 py-3">
      <div
         class="bg-error-700 bg-opacity-80 flex justify-center items-center rounded-full h-12 w-12"
      >
         <ExclamationCircleIcon
            class="h-6 w-6 text-white"
         ></ExclamationCircleIcon>
      </div>
      <p class="text-lg font-bold mt-5">{{ t('logoutModal.title') }}</p>
      <p data-test="logout-message" class="text-sm text-gray-500 mt-2">
         {{ t('logoutModal.confirmMessage') }}
      </p>
      <div class="flex w-full gap-4 mt-5">
         <BaseButton
            variant="outlined"
            class="w-full"
            border-color="gray-25"
            color="black"
            data-test="cancel-logout"
            @click="cancel"
         >
            {{ t('logoutModal.cancelButton') }}
         </BaseButton>
         <BaseButton
            data-test="confirm-logout"
            class="w-full"
            color="yellow-50"
            @click="logOut"
         >
            {{ t('logoutModal.logoutButton') }}
         </BaseButton>
      </div>
   </div>
</template>
