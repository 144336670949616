import type { RouteLocationNormalizedGeneric } from 'vue-router';

export default defineNuxtRouteMiddleware(
   async (
      to: RouteLocationNormalizedGeneric,
      from: RouteLocationNormalizedGeneric
   ) => {
      const { page } = useSegment();
      const { store: clientStore } = useAuthClientStorage();
      const { store: therapistStore } = useAuthTherapistStorage();
      page(to.fullPath);
      if (
         to?.query?.access_token &&
         to?.query?.refresh_token &&
         to?.query?.role
      ) {
         const { access_token, refresh_token, role } = to.query;
         if (role == 'client') {
            clientStore({
               access_token: access_token as string,
               refresh_token: refresh_token as string,
            });
            if (to.path) return navigateTo(to.path);
            else return navigateTo('/client/booking');
         } else if (role == 'therapist') {
            therapistStore({
               access_token: access_token as string,
               refresh_token: refresh_token as string,
            });
            if (to.path) return navigateTo(to.path);
            else return navigateTo('/therapist/calendar');
         }
      }
   }
);
