<script setup lang="ts">
   import * as HeroIcons from '@heroicons/vue/24/outline';
   import { CheckCircleIcon } from '@heroicons/vue/24/solid';

   export type Item = {
      name: string;
      image?: string | undefined;
      icon?: string | undefined;
      description?: string | undefined;
      value: string;
   };
   const props = defineProps<{
      data: Array<Item>;
      multiple?: boolean;
      checkAppend?: boolean;
      hoverBg?: boolean;
      size?: 'sm' | 'md' | 'lg';
      inline?: boolean;
      show2Gender?: boolean;
   }>();
   const value = defineModel<string | string[] | undefined>('value', {
      required: false,
   });
   const addItem = (itemValue: string) => {
      if (props.multiple) {
         let items = value.value as string[];

         if (!Array.isArray(items)) {
            items = [];
         }

         const itemIndex = items.indexOf(itemValue);

         if (itemIndex !== -1) {
            items.splice(itemIndex, 1);
         } else {
            items.push(itemValue);
         }

         value.value = items;
      } else {
         value.value = itemValue;
      }
   };
   const isActiveClass = (itemValue: string) => {
      if (props.multiple) {
         return (value.value as string[]).includes(itemValue);
      } else {
         return value.value === itemValue;
      }
   };

   const getIcon = (iconName: string) => {
      return (HeroIcons as any)[iconName];
   };
   const { t } = useI18n();
</script>
<template>
   <div class="flex gap-4" :class="inline ? 'flex-row' : 'flex-col'">
      <template v-for="item in data" :key="item.value">
         <div
            v-if="
               !show2Gender ||
               (show2Gender &&
                  (item.name.toLowerCase() ==
                     t(`registerStep.Male`).toLowerCase() ||
                     item.name.toLowerCase() ==
                        t(`registerStep.Female`).toLowerCase()))
            "
         >
            <div
               @click.prevent="addItem(item.value)"
               :data-test="item?.value"
               :id="item?.value"
               class="border cursor-pointer sm:min-h-14 rounded-xl px-4 py-2 sm:py-4 sm:px-6 flex justify-between items-center"
               :class="{
                  'border-primary-500 border-2': isActiveClass(item.value),
                  'bg-primary-500/10': hoverBg && isActiveClass(item.value),
                  'border-gray-400': true,
                  ' sm:min-w-56': inline,
               }"
            >
               <div class="flex gap-4 items-center">
                  <div v-if="!checkAppend">
                     <CheckCircleIcon
                        v-if="isActiveClass(item.value)"
                        class="h-5 w-5 sm:h-6 sm:w-6 text-primary-500"
                     />
                     <p
                        v-else
                        class="h-5 w-5 border border-gray-100 rounded-full"
                     ></p>
                  </div>
                  <div v-if="item.image">
                     <img :src="item.image" class="w-8 h-8 md:w-11 md:h-11" />
                  </div>
                  <div v-if="item.icon">
                     <component
                        :is="getIcon(item.icon)"
                        class="w-7 h-7 md:w-8 md:h-8 text-primary-500"
                     />
                  </div>
                  <div class="flex flex-col justify-between">
                     <p
                        class="text-black"
                        :class="
                           size === 'sm' ?
                              'text-xs md:text-sm font-medium'
                           :  'text-sm md:text-base font-semibold'
                        "
                     >
                        {{ item.name }}
                     </p>
                     <p class="text-xs text-gray-200" v-if="item.description">
                        <span v-html="item.description"></span>
                     </p>
                  </div>
               </div>
               <div v-if="checkAppend">
                  <CheckCircleIcon
                     v-if="isActiveClass(item.value)"
                     class="h-6 w-6 text-primary-500"
                  />
                  <p
                     v-else
                     class="h-5 w-5 border border-gray-100 rounded-full"
                  ></p>
               </div>
            </div>
            <slot
               name="append-detail"
               :item="item"
               :isActive="isActiveClass(item.value)"
            ></slot>
         </div>
      </template>
   </div>
</template>
