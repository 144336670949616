export default defineNuxtPlugin(() => {
   const config = useRuntimeConfig();

   const initSegment = () => {
      const analytics = (window.analytics = window.analytics || []);

      if (!analytics.initialize) {
         if (analytics.invoked) {
            console.error('Segment snippet included twice.');
            return;
         }

         analytics.invoked = true;
         analytics.methods = [
            'trackSubmit',
            'trackClick',
            'trackLink',
            'trackForm',
            'pageview',
            'identify',
            'reset',
            'group',
            'track',
            'ready',
            'alias',
            'debug',
            'page',
            'screen',
            'once',
            'off',
            'on',
            'addSourceMiddleware',
            'addIntegrationMiddleware',
            'setAnonymousId',
            'addDestinationMiddleware',
            'register',
         ];

         analytics.factory = (method) => {
            return function () {
               if (analytics.initialized) {
                  return analytics[method].apply(analytics, arguments);
               }

               const args = Array.prototype.slice.call(arguments);
               if (
                  [
                     'track',
                     'screen',
                     'alias',
                     'group',
                     'page',
                     'identify',
                  ].indexOf(method) > -1
               ) {
                  const canonical = document.querySelector(
                     "link[rel='canonical']"
                  );
                  args.push({
                     __t: 'bpc',
                     c: canonical && canonical.getAttribute('href'),
                     p: location.pathname,
                     u: location.href,
                     s: location.search,
                     t: document.title,
                     r: document.referrer,
                  });
               }

               args.unshift(method);
               analytics.push(args);
               return analytics;
            };
         };

         // Define the load function before using it
         analytics.load = function (key, options) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            // Use the config value directly here
            script.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;
            script.setAttribute(
               'data-global-segment-analytics-key',
               'analytics'
            );

            const firstScript = document.getElementsByTagName('script')[0];
            firstScript.parentNode.insertBefore(script, firstScript);
            analytics._loadOptions = options;
         };

         // Generate analytics methods
         for (let i = 0; i < analytics.methods.length; i++) {
            const method = analytics.methods[i];
            analytics[method] = analytics.factory(method);
         }

         const SEGMENT_ID = config.public.SEGMENT_CLIENT_ID;

         analytics._writeKey = SEGMENT_ID;
         analytics.SNIPPET_VERSION = '5.2.0';

         // Use the config value here
         analytics.load(SEGMENT_ID);

         // Add a check to ensure the page call happens after load
         setTimeout(() => {
            analytics.page();
         }, 100);
      }
   };

   // Only run on client-side
   if (process.client) {
      // Wrap in setTimeout to ensure DOM is ready
      setTimeout(initSegment, 0);
   }
});
