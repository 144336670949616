import apiRoutes from '@/const/apiRoutes';
import type { ClientLoginInformation } from '~/types/ClientLoginInformation';
import type { EnumResendTokenAction } from '../types/misc.enum';
export const useTherapistAuthStore = defineStore('therapistsAuth', () => {
   const { setSnackbarDetail } = useSnackbarStore();
   const { store, clear } = useAuthTherapistStorage();
   const router = useRouter();
   const { setUser } = useSentry();
   const { reset } = useSegment();

   const loginAsTherapist = async (detail: {
      email: string;
      password: string;
   }) => {
      let { data, error } = await useTherapistApi<ClientLoginInformation>(
         apiRoutes?.therapist?.auth?.signin,
         {
            method: 'POST',
            body: detail,
         }
      );
      if (data?.value) {
         store(data?.value?.token);
         setSnackbarDetail({
            show: true,
            title: data?.value?.message,
            type: 'message',
         });
         setTimeout(() => {
            router.push('/therapist/schedule');
         }, 500);
      }
      if (error?.value) {
         setSnackbarDetail({
            show: true,
            title: error?.value?.data?.message,
            type: 'error',
         });
      }
   };
   const logoutAsTherapist = () => {
      reset();
      setUser(null);
      clear();
      location.reload();
   };
   const forgetPasswordAsTherapist = async (email: string) => {
      let { data, error } = await useTherapistApi(
         apiRoutes?.therapist?.auth.forgetPassword,
         {
            method: 'POST',
            body: {
               email,
            },
         }
      );
      if (data?.value)
         setSnackbarDetail({
            show: true,
            title: (data.value as { message: string })?.message,
            type: 'message',
         });
      if (error?.value)
         setSnackbarDetail({
            show: true,
            title: error.value.data.message,
            type: 'error',
         });
      return { data, error };
   };
   const resetPasswordAsTherapist = async (dataObject: {
      email: string;
      new_password: string;
      confirm_password: string;
      token: string;
   }) => {
      let { data, error } = await useTherapistApi<{
         client_id: string;
         message: string;
         token: {
            access_token: string;
            refresh_token: string;
         };
      }>(apiRoutes?.therapist?.auth.resetPassword, {
         method: 'POST',
         body: dataObject,
      });
      if (data?.value) {
         setSnackbarDetail({
            show: true,
            title: (data.value as { message: string })?.message,
            type: 'message',
         });
         store(data?.value?.token);
         setTimeout(() => {
            router.push('/therapist/calendar');
         }, 500);
      }
      if (error?.value)
         setSnackbarDetail({
            show: true,
            title: error.value.data.message,
            type: 'error',
         });
      return { data, error };
   };
   const resendTokenAsTherapist = async (email: string, action: string) => {
      let { data, error } = await useTherapistApi<{ message: string }>(
         apiRoutes?.therapist?.auth.resendVerifyToken,
         {
            method: 'POST',
            body: {
               action,
               email,
            },
         }
      );
      if (data.value)
         setSnackbarDetail({
            show: true,
            title: data.value.message,
            type: 'message',
         });

      if (error.value)
         setSnackbarDetail({
            show: true,
            title: error.value.data.message,
            type: 'error',
         });
      return { data, error };
   };
   const verifyToken = async (body: {
      action: EnumResendTokenAction;
      email: string;
      token: string;
   }) => {
      let { data, error } = await useTherapistApi<ClientLoginInformation>(
         apiRoutes?.therapist?.auth.verifyToken,
         {
            method: 'POST',
            body,
         }
      );
      if (data.value) {
         setSnackbarDetail({
            show: true,
            title: data.value.message,
            type: 'message',
         });
         store(data?.value?.token);
         setTimeout(async () => {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const { updateTimeZone } = useTimeZoneStore();
            await updateTimeZone(timeZone, false, true);
         }, 500);
      }
      if (error?.value)
         setSnackbarDetail({
            show: true,
            title: error.value.data.message,
            type: 'error',
         });
      return { data, error };
   };

   return {
      loginAsTherapist,
      logoutAsTherapist,
      forgetPasswordAsTherapist,
      resetPasswordAsTherapist,
      resendTokenAsTherapist,
      verifyToken,
   };
});
