<script setup lang="ts">
   import { CheckCircleIcon } from '@heroicons/vue/24/outline';
   const { clientInformation, isClient } = storeToRefs(useClientInfo());
   const redirect = (action: boolean) => {
      useClientInfo().setRedirectionUrl(true);
      if (action) {
         navigateTo('/auth/client/register/therapy-reasons');
      } else {
         navigateTo('/therapist-recommendations?redirect=profile');
      }
   };
   const changeTherapistModal = ref(false);
   const { t } = useI18n();
</script>
<template>
   <div v-if="isClient">
      <div
         v-if="clientInformation?.therapist?.id"
         id="activeTherapistName"
         class="ps-6 pe-1 py-9 border-b border-gray-25 flex gap-4 align-top"
      >
         <div>
            <BaseAvatar
               :image-id="clientInformation?.therapist?.image"
               role="client"
               style="
                  min-width: 50px;
                  min-height: 50px;
                  max-width: 50px;
                  max-height: 50px;
               "
               classes="rounded-full object-cover"
               :alt="`${clientInformation?.therapist?.name} Profile Picture`"
            />
         </div>
         <div @click="navigateTo('/client/therapist/profile')">
            <p
               class="text-sm max-w-32 cursor-pointer whitespace-nowrap font-bold truncate"
               :title="clientInformation?.therapist?.name"
            >
               {{ clientInformation?.therapist?.name }}
            </p>
            <p class="text-xs text-gray-300 mt-1">
               {{ clientInformation?.therapist.title }}
            </p>
            <p
               v-if="clientInformation?.therapist?.experience"
               class="py-1 flex gap-1 items-center mt-2 px-2 text-xs text-secondary-500 border rounded-full border-secondary-500"
            >
               <CheckCircleIcon class="w-4 h-4" />
               {{ clientInformation?.therapist?.experience }}
               <span>{{ t('header.yearsInPractice') }}</span>
            </p>
         </div>
      </div>
      <div v-else class="px-6 py-7 border-b border-gray-25">
         <BaseButton
            color="primary-500"
            class="w-full text-center"
            @click="changeTherapistModal = true"
         >
            <span class="w-full">
               {{ t('header.selectTherapist') }}
            </span>
         </BaseButton>
      </div>
      <BaseDialog size="sm" v-model:open-dialog="changeTherapistModal">
         <template #header></template>
         <div class="grid grid-cols-12">
            <div class="col-span-12 flex gap-3 mb-6">
               {{ t('therapistProfile.changeTherapistTitle') }}
               <XMarkIcon
                  @click="changeTherapistModal = false"
                  class="h-12 w-12 cursor-pointer"
               ></XMarkIcon>
            </div>
            <div class="col-span-12 gap-5 flex justify-between">
               <BaseButton
                  @click="redirect(false)"
                  block
                  variant="outlined"
                  color="primary-500"
               >
                  {{ t('No') }}
               </BaseButton>
               <BaseButton @click="redirect(true)" block color="primary-500">
                  {{ t('Yes') }}
               </BaseButton>
            </div>
         </div>
      </BaseDialog>
   </div>
</template>
