export default defineNuxtPlugin(() => {
   // Initialize Customer.io script
   const script = document.createElement('script');
   script.type = 'text/javascript';
   script.async = true;
   script.id = 'cio-tracker';
   script.setAttribute('data-site-id', '5dac8ccdc465db144d68');
   script.src = 'https://assets.customer.io/assets/track-eu.js';
   script.setAttribute('data-auto-track-page', 'false');
   const existingScript = document.getElementsByTagName('script')[0];
   existingScript.parentNode.insertBefore(script, existingScript);

   // Define the `_cio` array with required methods
   window._cio = window._cio || [];
   const methods = ['load', 'identify', 'sidentify', 'track', 'page', 'reset'];
   methods.forEach((method) => {
      window._cio[method] = (...args) => {
         window._cio.push([method, ...args]);
      };
   });
});
