export const useCustomerIo = () => {
   const identifyUser = (user: { id: string; email: string }) => {
      if (typeof window !== 'undefined' && (window as any)._cio) {
         (window as any)._cio.identify({
            id: user?.id,
            email: user?.email,
         });
      }
   };
   const resetUser = () => {
      if (typeof window !== 'undefined' && (window as any)._cio) {
         (window as any)._cio.reset();
      }
   };
   const trackEvent = (event: string, data?: any) => {
      if (typeof window !== 'undefined' && (window as any)._cio) {
         (window as any)._cio.track(event, data);
      }
   };
   const trackPageView = (page: string) => {
      if (typeof window !== 'undefined' && (window as any)._cio) {
         (window as any)._cio.page(page);
      }
   };

   return { identifyUser, resetUser, trackEvent, trackPageView };
};
